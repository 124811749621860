.all-projects-container {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  padding: 0 9%;
}

.projects-table-container {
  top: 20%;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
}

.projects-container {
  display: flex;
  flex-direction: column;
}

.table-body-row,
.table-header-cell,
.table-cell {
  display: table-cell;
  text-align: left;
  padding: 10px;
}

.table-cell,
.table-header-cell {
  text-align: left;
  padding-left: 10px;
}

.table-cell-actions,
.table-cell-instance {
  text-align: right;
  padding-right: 10px;
}

.border-box {
  border: 2px solid #e6e6e9;
  margin-bottom: 20px;
}

.motif-table {
  height: 500px !important;
  width: 100%;
}

.modal-instance {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    align-items: flex-start;
  }
}

.modalContent-instance {
  background-color: #fefefe;
  padding: 0px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  text-align: center;
  display: flex;
  flex-wrap: wrap; // For responsive adjustments
  @media (max-width: 768px) {
    flex-direction: column;
    height: 50vh;
  }
  @media (max-width: 480px) {
    height: 60vh;
    width: 95%;
  }
}

.img-instance {
  width: 50%;
  background-color: rgb(1, 1, 64);

  @media (max-width: 768px) {
    width: 100%;
  }
}

.img-instance img {
  width: 100%;
  height: auto; // Maintain image aspect ratio
}

.text-instance {
  width: 50%;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
}

.text-instance-body {
  width: 80%;
  position: absolute;
  top: 5%;
  left: 10%;

  @media (max-width: 768px) {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
  }
}

.header-instance {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.body-instance {
  border: 1px solid rgba(230, 230, 233, 1);
  background: rgba(255, 255, 255, 1);
  padding: 0;
  height: 25vh;
  overflow: auto;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 20vh;
  }
  @media (max-width: 480px) {
    height: 18vh;
  }
}

.nextButtonInstance {
  margin-top: 5%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-right: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  }
}

button.nextButtonInstance {
  background-color: rgba(46, 46, 56, 1) !important;
  width: 100%;
}

.countries-instance-label {
  border-style: none;
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.countries-instance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-top: 10px;
  width: 100%;
  border: none;
  background-color: transparent;

  @media (max-width: 768px) {
    padding: 8px 5px;
  }
}
.countries-instance:hover {
  background-color: rgba(243, 243, 245, 1);
}

.cancel-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.description-instance {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #23232f;

  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: 200;
    line-height: 20px;
  }
}
